import genderMap from '@app/store/constants/genderMap';
import { replaceTtsTags } from './replaceTtsTags';
import getNameDeclension from './getNameDeclension';

function extractText(slideContains) {
  for (let i = 0; i < slideContains.length; i++) {
    const item = slideContains[i];
    const _template = item.template;

    if (_template) {
      return item;
    }
  }
  return null;
}

export default (html, contains, content, topic) => {
  const { gender } = topic;
  const detectedGender = genderMap[gender] || 'androgynous';
  const { dir } = topic;
  let result = html;

  const item = extractText(contains);

  let _value = item.value;
  let _template = item.template;

  if (!_value && item.param) {
    const param = content.find(_i => _i.id === item.param);

    if (param) _value = param.value;
  }

  if (_template && !_value && !item.param) {
    _template = replaceTtsTags(_template);
    if (dir) {
      const variables = _template.match(/%\{([^{}]*)%\{([^{}]+)}%([^{}]*)}%/g);

      if (variables && variables.length) {
        variables.forEach((matchValue) => {
          const regex = /%\{([^{}]*)%\{([^{}]+)}%([^{}]*)}%/g;
          const match = regex.exec(matchValue);
          const parsedParam = match[2];

          const hasCase = parsedParam.split(/__/);
          if (hasCase.length > 1) {
            const paramValue = hasCase[0];
            const caseName = hasCase[1];
            const parsedGender = hasCase[2];

            const contValue = content.find(({ id }) => id === paramValue);
            const replacement = contValue ? contValue.value : '';
            const finalGender = parsedGender || detectedGender;

            const nameDeclension = getNameDeclension(finalGender, replacement, caseName);

            const regexTemp = `%{${match[1]}%{${match[2]}}%${match[3]}}%`;
            _template = _template.replace(new RegExp(regexTemp, 'g'), match[1] + nameDeclension + match[3]);
          } else {
            const contValue = content.find(({ id }) => id === parsedParam);
            const replacement = contValue ? contValue.value : '';
            const regexTemp = `%{${match[1]}%{${match[2]}}%${match[3]}}%`;
            _template = _template.replace(new RegExp(regexTemp, 'g'), match[1] + replacement + match[3]);
          }
        });
      }
    } else {
      const regex = /%{(.*?)}%/g;
      const variables = _template.match(regex);

      if (variables && variables.length) {
        variables.forEach((matchValue) => {
          const parsedParam = matchValue.replace(/%{|}%/g, '');

          const hasCase = parsedParam.split(/__/);
          if (hasCase.length > 1) {
            const paramValue = hasCase[0];
            const caseName = hasCase[1];
            const parsedGender = hasCase[2];

            const contValue = content.find(({ id }) => id === paramValue);
            const replacement = contValue ? contValue.value : '';
            const finalGender = parsedGender || detectedGender;

            const nameDeclension = getNameDeclension(finalGender, replacement, caseName);

            _template = _template.replace(new RegExp(`%{${parsedParam}}%`, 'g'), nameDeclension);
          } else {
            const contValue = content.find(({ id }) => id === parsedParam);
            const replacement = contValue ? contValue.value : '';
            _template = _template.replace(new RegExp(`%{${parsedParam}}%`, 'g'), replacement);
          }
        });
      }
    }

    _value = _template;
  }

  if (_value) result = result.replace(new RegExp(`{%${item.field}%}`, 'g'), _value);

  return result;
};
