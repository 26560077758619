import bufferToBlob from './bufferToBlob';

async function decodeBlob(blob, numberOfChannels, sampleRate) {
  const arrayBuffer = await blob.arrayBuffer();
  const offlineContext = new OfflineAudioContext(numberOfChannels, 1, sampleRate);
  // eslint-disable-next-line no-return-await
  return await offlineContext.decodeAudioData(arrayBuffer);
}

export default async function mergeAudio(blobs, sampleRate = 44000, numberOfChannels = 2) {
  const buffers = await Promise.all(blobs.map(blob => decodeBlob(blob, numberOfChannels, sampleRate)));
  const totalLength = buffers.reduce((sum, buffer) => sum + buffer.length, 0);

  const offlineContext = new OfflineAudioContext(numberOfChannels, totalLength, sampleRate);
  const mergedBuffer = offlineContext.createBuffer(numberOfChannels, totalLength, sampleRate);

  let offset = 0;
  buffers.forEach(buffer => {
    for (let channel = 0; channel < numberOfChannels; channel++) {
      mergedBuffer.getChannelData(channel).set(buffer.getChannelData(channel), offset);
    }
    offset += buffer.length;
  });

  return bufferToBlob(mergedBuffer);
}
