import audioBufferToWav from './audioBufferToWav';

export default async (blob, currentTime = 0, skipEndTime = 0, sampleRate = 44000, numberOfChannels = 2) => {
  const arrayBuffer = await blob.arrayBuffer();

  const tempContext = new OfflineAudioContext(1, 1, sampleRate);
  const audioBuffer = await tempContext.decodeAudioData(arrayBuffer);

  const newDuration = Math.max(0, audioBuffer.duration - skipEndTime);
  const length = Math.ceil(sampleRate * newDuration);

  const offlineContext = new OfflineAudioContext(numberOfChannels, length, sampleRate);

  const trimmedBuffer = offlineContext.createBuffer(
    audioBuffer.numberOfChannels,
    offlineContext.sampleRate * newDuration,
    offlineContext.sampleRate,
  );

  for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
    trimmedBuffer.copyToChannel(
      audioBuffer.getChannelData(i).slice(offlineContext.sampleRate * currentTime, offlineContext.sampleRate * newDuration),
      i,
    );
  }

  const wav = audioBufferToWav(trimmedBuffer);
  return new Blob([wav], { type: 'audio/wav' });
};
