import audioBufferToWav from './audioBufferToWav';

export default (buffer) => {
  const offlineContext = new OfflineAudioContext(buffer.numberOfChannels, buffer.length, buffer.sampleRate);
  const source = offlineContext.createBufferSource();
  source.buffer = buffer;
  source.connect(offlineContext.destination);
  source.start();

  return new Promise(resolve => {
    // eslint-disable-next-line promise/catch-or-return
    offlineContext.startRendering().then(renderedBuffer => {
      const wavBlob = audioBufferToWav(renderedBuffer);
      return resolve(new Blob([wavBlob], { type: 'audio/wav' }));
    });
  });
};
