import axios from 'axios';
import { apiV4 } from '@app/services/HttpService';
import genderMap from '@app/store/constants/genderMap';
import { fetchAudio, fetchAudios, fetchVariable } from './TtsAudioService';
import getNameDeclension from '../helpers/getNameDeclension';
import trimAudio from '../helpers/trimAudio';
import mergeAudioBlobs from '../helpers/mergeAudio';

export const ttsDefaultOptions = {
  format: 'opus',
  voice: 'Bys_24000',
  numChannels: 1,
  sampleRate: 22050,
  play: false,
};

class TtsService {
  constructor(options = {}) {
    this.options = {
      voice: options.voice || ttsDefaultOptions.voice,
      sampleRate: options.sampleRate || ttsDefaultOptions.sampleRate,
      numChannels: ttsDefaultOptions.numChannels,
    };

    const AudioContext = window.AudioContext || window.webkitAudioContext;
    this.audioCtx = new AudioContext();
    this.sourceNodes = [];
    this.audioUrls = [];
    this.buffers = [];
    this.pausedAt = null;
    this.paused = false;
    this.startedAt = null;
    this.playSecond = false;
    this.playThird = false;
    this.currentSourceIndex = null;
    this.cancelTokens = [];
  }

  loadData(text) {
    const token = axios.CancelToken.source();
    this.cancelTokens.push(token);

    return apiV4.post('tts/audio',
      {
        voiceName: this.options.voice,
        text,
        format: 'opus',
      },
      {
        responseType: 'arraybuffer',
        cancelToken: token.token,
      })
      .then(({ data }) => this.decodeAudioData(data)
        .then((buffer) => {
          const sourceNode = this.createSourceNode(buffer);
          this.sourceNodes.push(sourceNode);
          this.buffers.push(buffer);
          return buffer;
        }))
      // eslint-disable-next-line no-console
      .catch(err => console.error(err));
  }

  createSourceNode(buffer) {
    const sourceNode = this.audioCtx.createBufferSource();
    sourceNode.connect(this.audioCtx.destination);
    sourceNode.buffer = buffer;
    sourceNode.isAudio = false;
    return sourceNode;
  }

  decodeAudioData(data) {
    return new Promise((resolve, reject) => {
      this.audioCtx.decodeAudioData(data, buffer => resolve(buffer), err => reject(err));
    });
  }

  playAudio() {
    this.play();
  }

  pauseAudio() {
    this.stop();
  }

  setDataLoadedCallback(loaded) {
    if (typeof this.onFinishLoading === 'function') {
      this.onFinishLoading(loaded);
    }
  }

  clearAudios() {
    this.buffers = [];
    this.sourceNodes.map(node => (node.isAudio ? node.pause() : node.disconnect(this.audioCtx.destination)));
    this.audioUrls.map(url => URL.revokeObjectURL(url));
    this.audioUrls = [];
    this.sourceNodes = [];
    this.playSecond = false;
    this.playThird = false;
    this.currentSourceIndex = null;
  }

  clearTimings() {
    this.pausedAt = null;
    this.paused = false;
    this.startedAt = null;
  }

  cancelRequests() {
    this.cancelTokens.map((token, index) => token.cancel(`cancel request ${index}`));
    this.cancelTokens = [];
  }

  clearData() {
    this.clearAudios();
    this.clearTimings();
    this.cancelRequests();
    this.setDataLoadedCallback(false);
  }

  play() {
    if (typeof this.onAudioPlaying === 'function') {
      this.onAudioPlaying();
    }

    const onEndedCallback = this.sourceNodes[this.currentSourceIndex].onended;
    const sourceNode = this.sourceNodes[this.currentSourceIndex];
    if (sourceNode.isAudio) {
      sourceNode.play();
    } else {
      this.sourceNodes[this.currentSourceIndex] = this.createSourceNode(this.buffers[this.currentSourceIndex]);
      this.sourceNodes[this.currentSourceIndex].onended = onEndedCallback;

      if (this.sourceNodes[this.currentSourceIndex]) {
        if (this.pausedAt) {
          this.startedAt = Date.now() - this.pausedAt;
          this.sourceNodes[this.currentSourceIndex].start(0, this.pausedAt / 1000);
        } else {
          this.startedAt = Date.now();
          this.sourceNodes[this.currentSourceIndex].start(0);
        }
      }
    }
    this.paused = false;
  }

  stop() {
    if (typeof this.onAudioPaused === 'function') {
      this.onAudioPaused();
    }

    const sourceNode = this.sourceNodes[this.currentSourceIndex];
    if (sourceNode) {
      if (sourceNode.isAudio) sourceNode.pause();
      else sourceNode.stop(0);
    }
    this.pausedAt = Date.now() - this.startedAt;
    this.paused = true;
  }

  // eslint-disable-next-line class-methods-use-this
  extractText(slideContains) {
    for (let i = 0; i < slideContains.length; i++) {
      const item = slideContains[i];
      const _template = item.template;

      if (_template) {
        return _template;
      }
    }
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  hasVariable(fileName) {
    return fileName.indexOf('__') !== -1;
  }

  // eslint-disable-next-line class-methods-use-this
  extractVariableFromText(slideText, content, topic) {
    const matches = [];
    const regex = /%\{([^{}]*)%\{([^{}]+)}%([^{}]*)}%/g;
    const { gender } = topic;
    const detectedGender = genderMap[gender] || 'androgynous';

    let match;
    /* eslint-disable no-cond-assign */

    while ((match = regex.exec(slideText)) !== null) {
      const hasCase = match[2].split(/__/);
      if (hasCase.length > 1) {
        const paramKey = hasCase[0];
        const caseName = hasCase[1];
        const parsedGender = hasCase[2];

        const contValue = content.find(({ id }) => id === paramKey);
        const replacement = contValue ? contValue.value : '';
        const finalGender = parsedGender || detectedGender;

        const nameDeclension = getNameDeclension(finalGender, replacement, caseName);
        matches.push(match[1] + nameDeclension + match[3]);
      } else {
        const paramKey = match[2];
        const contValue = content.find(({ id }) => id === paramKey);
        const replacement = contValue ? contValue.value : '';
        matches.push(match[1] + replacement + match[3]);
      }
    }
    return matches;
  }

  async processBlob(blob, currentTime = 0.002) {
    const blobUrl = URL.createObjectURL(blob);
    this.audioUrls.push(blobUrl);

    const audio = new Audio(blobUrl);
    audio.onloadeddata = () => {
      audio.currentTime = currentTime;
    };

    audio.onpause = (event) => {
      audio.playing = false;
      this.onAudioPaused(event);
    };

    audio.onplay = (event) => {
      audio.playing = true;
      this.onAudioPlaying(event);
    };

    audio.onended = () => {
      audio.playing = false;
      this.currentSourceIndex++;
      if (this.currentSourceIndex >= this.sourceNodes.length) {
        this.audiosEnded();
        return;
      }

      if (this.sourceNodes[this.currentSourceIndex]) {
        this.playAudio();
      }
    };
    audio.isAudio = true;
    this.sourceNodes.push(audio);
  }

  // eslint-disable-next-line class-methods-use-this
  async loadAudios({ slideContains, dir, lang, gender, files, audioPrefix, content, topic }) {
    const sampleRate = 22050;
    const numberOfChannels = 1;

    this.currentSourceIndex = 0;
    const filtered = files.filter(x => x.fileName.indexOf(`${audioPrefix}_`) !== -1);
    const slideText = this.extractText(slideContains);
    const variables = this.extractVariableFromText(slideText, content, topic);

    const audioBlobs = [];

    for (let i = 0; i < filtered.length; i++) {
      const { fileName } = filtered[i];
      // eslint-disable-next-line no-await-in-loop
      const audioBlob = await fetchAudio({ dir, lang, gender, fileName });

      if (this.hasVariable(fileName)) {
        const variable = variables[i];
        // eslint-disable-next-line no-await-in-loop
        const variableBlob = await fetchVariable(variable);
        // eslint-disable-next-line no-await-in-loop
        const trimmedBlob = await trimAudio(variableBlob, 0.07, 0.15, sampleRate, numberOfChannels);
        audioBlobs.push(audioBlob, trimmedBlob);
      } else {
        audioBlobs.push(audioBlob);
      }
    }

    // eslint-disable-next-line no-await-in-loop
    const mergedBlob = await mergeAudioBlobs(audioBlobs, sampleRate, numberOfChannels);
    // eslint-disable-next-line no-await-in-loop
    await this.processBlob(mergedBlob);

    this.setDataLoadedCallback(true);
    this.play();
  }

  initAudio({
    slideContains,
    dir,
    lang,
    gender,
    audioPrefix,
    topic,
    content,
  }) {
    this.clearAudios();
    return fetchAudios({
      dir,
      lang,
      gender,
    })
      .then(({ files }) => this.loadAudios({
        slideContains,
        dir,
        lang,
        gender,
        files,
        audioPrefix,
        topic,
        content,
      }));
  }

  synthesize(text) {
    this.loadData(text)
      .then(() => {
        this.setDataLoadedCallback(true);
        this.currentSourceIndex = 0;

        this.sourceNodes[this.currentSourceIndex].onended = () => {
          if (!this.paused) {
            if (typeof this.audiosEnded === 'function') {
              this.audiosEnded();
            }
            this.currentSourceIndex = null;
          }
        };

        if (typeof this.onAudioPlaying === 'function') {
          this.onAudioPlaying();
        }

        this.clearTimings();
        this.play();
        return null;
      })
      // eslint-disable-next-line no-console
      .catch(e => console.error(e));
  }
}

export default TtsService;
